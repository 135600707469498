<template>
  <div class="container py-5 py-md-10">
      <h1 class="mx-auto text-center mb-5 mb-md-10" v-html="$t('Registration.createYourAccountHeader')"></h1>
      <div class="d-flex flex-md-row-custom flex-column gap-2 justify-content-between">
          <AccountCreationContainer 
              :title="$t('signIn.label.buyerAccount')"
              :desc="$t('Registration.createYourAccountDesc', ['buy'])"
              btnDesc="Create a Buyer Account"
              :checklist="buyerChecks"
              :btnAction="() => selectAccountType(1)"
              />
              <AccountCreationContainer 
              :title="$t('signIn.label.sellerAccount')"
              :desc="$t('Registration.createYourAccountDesc', ['sell'])"
              btnDesc="Create a Seller Account"
              :checklist="sellerChecks"
              :btnAction="() => selectAccountType(2)"
          />
      </div>
  </div>
</template>

<script>
import AccountCreationContainer from '@/components/global/containers/AccountCreationContainer.vue'
export default {
    data() {
        return {
            buyerChecks: [
                {
                    key: 'buyCheck1',
                    val: this.$t("register.label.buyerblurb1")
                },
                {
                    key: 'buyCheck2',
                    val: this.$t("register.label.buyerblurb2")
                },
                {
                    key: 'buyCheck3',
                    val: this.$t("register.label.buyerblurb3")
                },
                {
                    key: 'buyCheck4',
                    val: this.$t("register.label.buyerblurb4")
                },
            ],
            sellerChecks: [
                {
                    key: 'buyCheck1',
                    val: this.$t("register.label.sellerblurb1")
                },
                {
                    key: 'buyCheck2',
                    val: this.$t("register.label.sellerblurb2")
                },
                {
                    key: 'buyCheck3',
                    val: this.$t("register.label.sellerblurb3")
                },
                {
                    key: 'buyCheck4',
                    val: this.$t("register.label.sellerblurb4")
                },
            ]
        }
    },
    components: {
        AccountCreationContainer
    },
    methods: {
        async selectAccountType(accountType) {
            this.$router.push({ name: "CreateAccountType", params: { type: accountType === 1 ? 'buyer' : 'seller' } });
        },
    }
}
</script>

<style lang="scss" scoped>
.flex-md-row-custom {
    @media screen and (min-width:992px) {
        flex-direction: row!important;
    }
}
</style>