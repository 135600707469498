<template>
  <div class="bg-white rounded shadow mx-auto registration-maxW">
  <div class="col-11 py-7 m-auto registrationPage">
    <form @submit.prevent="register">
      <div class="d-flex flex-column gap-1 gap-md-2 m-auto">
        <div v-show="accountType > 1">
        <label class="form-label pl-2 mb-1">{{$t("register.label.CountryRegion") }}</label>
        <b-form-select
          v-model="country"
          class="form-control input-pills bg-white input-height"
        >
          <!-- :class="{
            'country-valid': countryValidate,
            'country-not-valid': !countryValidate
          }" -->
          <b-form-select-option
            v-for="country in countries"
            :value="country"
            :key="country.Name"
          >
            {{ country.Name }}
          </b-form-select-option>
          <b-form-select-option-group label="-----------------">
            <b-form-select-option :value="null">
              {{ $t("topbar.button.noCountry") }}
            </b-form-select-option>
          </b-form-select-option-group>
        </b-form-select>
      </div>
      <div>
          <label class="form-label pl-2 mb-1">{{$t("contactInfo.label.firstName") }}</label>
          <b-form-input
            type="text"
            v-model="firstName"
            class="input-pills input-height"
            ></b-form-input>
            <!-- :state="firstNameValidate" -->
            <div class="text-left text-danger" v-if="firstNameValidate === false">
              <b
                class="text-danger m-0 ml-2 mini-font"
              >
                <b-icon icon="x-circle-fill"/> {{ $t("Validations.Blank") }}
              </b>
            </div>
          </div>
          
          
          <div>
            <label class="form-label pl-2 mb-1">{{$t("contactInfo.label.lastName") }}</label>
          <b-form-input
            type="text"
            v-model="lastName"
            class="input-pills input-height"
            />
            <!-- :state="lastNameValidate" -->
            <div class="text-left text-danger" v-if="lastNameValidate === false">
              <b
                class="text-danger m-0 ml-2 mini-font"
              >
                <b-icon icon="x-circle-fill"/> {{ $t('Validations.Blank') }}
              </b>
            </div>
          </div>
          
          <div>
          <label class="form-label pl-2 mb-1">{{$t("signIn.label.emailAddress") }}</label>
          <b-form-input
          autocomplete="off"
            type="email"
            class="input-pills input-height"
            v-model="email"
            />
            <!-- :state="availableEmail" -->
        </div>

        <div class="text-left" v-if="availableEmail === false">
            <b
              class="text-danger m-0 ml-2 mini-font"
            >
              <b-icon icon="x-circle-fill"/> {{emailFormatValid ? $t("register.label.EmailAlreadyInUse") : $t("Validations.email.pleaseEnter")}}
            </b>
        </div>

        <div class=" mt-0" v-show="accountType > 1">
          <div class="pb-0 pt-1">
            <label class="form-label pl-2 mb-1">{{
              $t("signIn.label.phone")
            }}</label>
          </div>

          <div class="pb-0 pt-0">
            <b-input-group class="row m-0">
              <VuePhoneNumberInput
                class="col p-0"
                v-model="mobile"
                :default-country-code="
                  $store.getters.country.Code
                    ? $store.getters.country.Code
                    : 'CA'
                "
                @update="
                  mobilePayload = $event;
                  checkMobile();
                "
                :translations="{
                  countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                  countrySelectorError: $t('Contact.countrySelectorError'),
                  phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                  example: $t('Contact.example'),
                }"
              />
              <!-- <b-input-group-append>
                <div
                  class="right-rounded border border-left-0 px-3 bg-white position-absolute h-100"
                  :class="mobileValidated ? 'border-success' : ''"
                  :style="{right:0}"
                >
                  <b-icon
                    v-if="mobileValidated"
                    icon="check"
                    variant="success"
                    scale="2"
                    style="margin-top: 15px;"
                  ></b-icon>
                </div>
              </b-input-group-append> -->
            </b-input-group>
          </div>
        </div>
        <div class="text-left" v-if="mobileValidated === false">
            <b
              class="text-danger m-0 ml-2 mini-font"
            >
              <b-icon icon="x-circle-fill"/>{{$t("Validations.mobile.validNumber") }}
            </b>
        </div>
        <div class="text-left" v-if="availableMobile === false">
            <b
              class="text-danger m-0 ml-2 mini-font"
            >
              <b-icon icon="x-circle-fill"/>{{$t("register.label.TheMobileAlreadyInUse") }}
            </b>
        </div>

        <div>
          <label class="form-label pl-2 mb-1">
            {{ $t("signIn.label.password") }}
            <b-icon id="passwordTips" icon="info-circle"/>
          </label>
          <b-tooltip
            target="passwordTips"
            placement="righttop"
            triggers="hover"
            :show.sync="showTip"
            variant="primary"
            >{{ $t("register.label.passwordInfo") }}</b-tooltip
          >
          <b-input-group>
            <template #append>
            <b-input-group-text
              class="bg-white border right-rounded"
              :class="{
                'border-danger': passwordValidate === false,
                'border-success': passwordValidate
              }"
              ><b-icon :icon="eyes" @click="showPassword()"/></b-input-group-text>
          </template>
          <b-form-input
            :type="passwordField"
            v-model="password"
            name="password"
            class="form-control left-rounded input-height"
            />
            <!-- :state="passwordValidate" -->
          </b-input-group>
          <div class="text-left" v-if="passwordValidate === false">
          <b
            class="text-danger m-0 ml-2 mini-font"
          >
            <b-icon icon="x-circle-fill"/>{{$t("register.label.passwordInfo") }}
          </b>
      </div>
      </div>
      </div>

      <i18n path="signIn.label.byProceeding" tag="p" class="text-center small my-4 px-2">
        <router-link :to="{ name: '' }">
          {{ $t("signIn.label.termsConditions") }}
        </router-link>
        <router-link :to="{ name: '' }">
          {{ $t("signIn.label.privacyPolicy") }}
        </router-link>
      </i18n>

      <section
        class="border border-danger mt-3 text-center p-0"
        v-show="errorMessage"
      >
        <b class="text-danger">{{ errorMessage }}</b>
      </section>
      <b-button
        pill
        size="md"
        variant="primary"
        class="w-100"
        type="submit"
      >
        {{ $t("signIn.button.createAccount") }}
      </b-button>
    </form>
    <p class="text-center pt-3 m-0 mini-font">
      {{ $t("signIn.label.haveAccount") }}
      <b-link @click="backToPrevRoute()">
        {{ $t("signIn.label.title") }}
      </b-link>
    </p>
  </div>
  </div>
</template>
<script>
import axios from "axios";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    // TheMobileValidation: () => import("./TheMobileValidation.vue"),
    VuePhoneNumberInput
  },
  props: {
    accountType: {
      required: true,
      type: Number
    },
    prevRoutePath: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      firstName: null,
      firstNameValidate: null,
      lastName: null,
      lastNameValidate: null,
      // fullNameFormat: /\S+ \S+/,
      noChecked: false,
      email: null,
      availableEmail: null,
      emailFormat: /\S+@\S+\.\S+/,
      emailFormatValid: null,
      mobile: null,
      availableMobile: null,
      mobileValidated: null,
      mobilePayload: null,
      verificationCode: null,
      password: null,
      passwordValidate: null,
      passwordField: "password",
      eyes: "eye",
      showTip: false,
      passwordRule1: /^.*(?=.{6,20})(?=.*\d)(?=.*[A-Z]).*$/, //minimum 6 chars with atleast 1 number, 1 upper letter
      passwordRule2: /^.*(?=.{8,20})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!-_]).*$/, // minimum 8 chars with atleast 1 number, lower, upper & special(@#$%&!-_&) char
      errorMessage: null,
      countryValidate: null
    };
  },
  methods: {
    backToPrevRoute() {
      this.$bvModal.show("signin");
      this.$router.push(this.prevRoutePath);
    },
    async register() {
      if (!this.inputCheck()) return false;
      //const DeviceName = window.navigator.userAgent
      const DeviceName = 'windows Device 10'
      // if buyer account
      if (this.accountType === 1) {
        try {
          const response = await axios.post("user/Register", {
            FirstName: this.firstName,
            LastName: this.lastName,
            Email: this.email,
            CountryCode: this.$store.getters.country.Code,
            Password: this.password,
            RoleType: 0,
            DeviceName
          });
          this.$store.dispatch("tokenID", response.data.UserToken.Token);
          localStorage.setItem("token", response.data.UserToken.Token);
          axios.defaults.headers.common["accesstoken"] =
            response.data.UserToken.Token;
          this.$store.dispatch("user", response.data.UserData);
          // pierre told remove this redirect 20 Jan 2023
          //this.$router.push(this.prevRoutePath)
        } catch (error) {
          this.errorMessage = error.response.data;
        }
        //else seller account
      } else if (this.accountType === 2) {
        this.checkMobile()
        if (!this.countryValidate || !this.availableMobile || !this.mobileValidated) {
          return false;
        }
        try {
          const response = await axios.post("user/Register", {
            FirstName: this.firstName,
            LastName: this.lastName,
            Email: this.email,
            Mobile: this.mobilePayload.formattedNumber,
            CountryCode: this.country.Code,
            Password: this.password,
            RoleType: 1,
            DeviceName
          });
          this.$store.dispatch("tokenID", response.data.UserToken.Token);
          localStorage.setItem("token", response.data.UserToken.Token);
          axios.defaults.headers.common["accesstoken"] =
            response.data.UserToken.Token;
          this.$store.dispatch("user", response.data.UserData);
        } catch (error) {
          console.log(error.response.data);
          this.errorMessage = error.response.data;
        }
      }
    },
    async checkEmail() {
      this.availableEmail = null;
      try {
        if (this.emailFormat.test(this.email)) {
          const response = await axios.post("user/IsEmailExist", {
            Email: this.email
          });
          this.availableEmail = !response.data;
          this.emailFormatValid = true;
        } else this.emailFormatValid = false
      } catch (error) {
        this.availableEmail = false;
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    async checkMobile() {
      this.availableMobile = null;
      this.mobileValidated = false;
      if (this.validMobile) {
        try {
          const response = await axios.post("user/IsMobileExist", {
            Mobile: this.mobilePayload.formattedNumber
          });
          this.availableMobile = !response.data;
          this.mobileValidated = true;
        } catch (error) {
          this.availableMobile = false;
          this.errorMessage = error.response.data;
          console.log(error.response.data);
        }
      }
    },
    inputCheck() {
      this.checkEmail()
      this.firstNameValidate = this.firstName ? true : false
      this.lastNameValidate = this.lastName ? true : false
      this.availableEmail = !this.email || !this.availableEmail ? false : true
      this.passwordValidate = !this.password || !this.passwordValidate ? false : this.passwordRule1.test(this.password)
      
      if (!this.firstName ||
       !this.lastName ||
       !this.email ||
       !this.availableEmail ||
       !this.password ||
       !this.passwordValidate) return false;
      return true
    },
    showPassword() {
      if (this.passwordField === "password") {
        (this.passwordField = "text"), (this.eyes = "eye-slash");
      } else if (this.passwordField === "text") {
        (this.passwordField = "password"), (this.eyes = "eye");
      }
    },
    exited() {
      this.$store.dispatch("register_fullName", null);
      this.$store.dispatch("register_emailAddress", null);
      this.$store.dispatch("register_countryCode", null);
    },

    checkCountry() {
      if (this.$store.getters.country.CanSell) {
        this.countryValidate = true;
      } else this.countryValidate = false;
    }
  },
  beforeMount() {
    this.checkCountry();
    this.$store.dispatch("doneRegister", false);
  },
  computed: {
    countries() {
      return this.$store.getters.activeSellerCountries;
    },
    country: {
      get() {
        return this.$store.getters.country;
      },
      set(e) {
        if (e) {
          this.$store.dispatch("setCountry", e);
          this.checkCountry();
        } else {
          this.$bvModal.show("registerCountryNotFound");
        }
      }
    },
    disableSubmit() {
      if (!this.firstName) {
        return true;
      }
      if (!this.lastName) {
        return true;
      }
      if (!this.email || this.availableEmail === false) {
        return true;
      }
      if (!this.password || this.passwordValidate === false) {
        return true;
      }
      if (this.accountType != 1 && !this.countryValidate) {
        return true;
      }
      if (this.accountType != 1 && !this.mobileValidated) {
        return true;
      }
      return false;
    },
    validMobile() {
      if (this.mobilePayload) {
        if (this.mobilePayload.isValid) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
.input-height {
  height:48px!important;
}
.registration-maxW{
  max-width: 530px;
}
.country-valid {
  border-color: #28a745 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
.country-not-valid {
  border-color: #dc3545 !important;
}
</style>

<style lang="scss">

.country-selector__input {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.input-tel__input {
  border-radius: 0px !important;
  border-right: 0px !important;
  // border-top-right-radius: 50px !important;
  // border-bottom-right-radius: 50px !important;
}

.select-country-container {
  // pointer-events: none;
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
</style>
