<template>
    <div class="mx-auto account-creation-container px-0 bg-white rounded border-8 border-left-0 border-right-0 border-bottom-0 border-primary text-center">
        <div class="py-2 bg-light-primary">
            <h5 class="m-0">{{title}}</h5>
        </div>
        <div class="p-4 gap-2 d-flex flex-column">
            <div>
                {{desc}}
            </div>
            <div v-if="!opened">
                <a class="text-primary" @click="opened = true">
                    {{$t('dashboard.label.SeeDetails')}}
                </a>
            </div>
            <div class="mx-auto text-left" v-else>
                <ul class="d-flex flex-column gap-2" v-for="check in checklist" :key="check.key">
                    <li class="d-flex gap-2">
                        <Check/>
                        <div>
                            {{ check.val }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn btn-primary rounded-pill py-3" @click="btnAction">
                {{btnDesc}}
            </div>
        </div>
    </div>
</template>

<script>
import Check from "vue-material-design-icons/Check.vue";
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        desc: {
            type: String,
            required: true
        },
        btnDesc: {
            type: String,
            required: true
        },
        to: {
            type: Object,
            required: true
        },
        checklist: {
            type: Array,
            required: true
        },
        btnAction: {
            type: Function,
            required: true
        }
    },
    components: {
        Check
    },
    name: 'AccountCreationContainer',
    data() {
        return {
            opened: false
        }
    },
    created() {
        if (window.innerWidth >= 992) {
            this.opened = true;
        }
    }
}
</script>

<style lang="scss">
.account-creation-container {
    height:fit-content;
    max-width:663px;
    @media screen and (min-width:1068px) {
        max-width:unset;
    }
}

.bg-light-primary {
    background-color: rgb(204, 225, 243);
}
</style>