<template>
  <div class="bg-secondary">
    <b-container class="py-3">
      <div class="ml-1 mb-2">
        <b-link @click="$router.back()">
          <b-icon icon="arrow-left" scale="1.5" class="mr-2" />
          {{ $t("listing.button.return") }}
        </b-link>
      </div>
      <div v-if="$store.getters.user">
        <TheRegisterValidation
          :accountType="accountType"
          :prevRoutePath="prevRoutePath"
        />
      </div>
      <div class="pb-3 pb-md-4 pb-lg-5" v-else>
        <h1 class="text-center p-3 p-md-4 p-lg-5">{{accountType === 1 ? $t('Registration.CreateBuyer') : $t('Registration.CreateSeller')}}</h1>
        <TheRegistration
          :accountType="accountType"
          :prevRoutePath="prevRoutePath"
        />
      </div>
    </b-container>
    <!-- <router-link :to="prevRoutePath">Previous Page</router-link> -->
  </div>
</template>

<script>
import TheRegistration from "@/components/global/TheRegistration.vue";
import TheRegisterValidation from "@/components/global/TheRegisterValidation.vue";

export default {
  components: {
    TheRegistration,
    TheRegisterValidation,
  },
  data() {
    return {
      prevRoute: null,
    };
  },
  computed: {
    accountType() {
      const accountType = this.$route.params.type === 'buyer' ? 1 : 2
      this.$store.dispatch("accountType", accountType);
      return this.$store.getters.accountType;
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : "/";
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let verified = false;
      let user = vm.$store.getters.user;
      if (user) {
        verified = user.IsEmailVerified && user.IsMobileVerified;
      }
      if (verified) {
        vm.$router.push({ name: "Home" });
      }
      if (
        vm.$store.getters.country.Code !== "CA" &&
        vm.$store.getters.accountType === 2
      ) {
        vm.$router.back();
        vm.$bvModal.show("registerCountryNotFound");
      }
      vm.prevRoute = from;

    });
  },
  beforeMount() {
    document.getElementById("navBar").style.display = "none";
  },
  beforeDestroy() {
    this.$store.dispatch("register_emailAddress", null);
    this.$store.dispatch("doneRegister", true);
    document.getElementById("navBar").style.display = "block";
  },
};
</script>

<style lang="scss" scoped>
.bg_image_seller {
  background-image: url("~@/assets/img/Register1.jpg");
  z-index: -999 !important;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.card-body {
  padding: 0;
}
</style>

<style lang="scss">
.v-application {
  .pb-3, .py-3 {
    padding-bottom: 1rem !important;
  }
  .pt-3, .py-3 {
    padding-top: 1rem !important;
  }
  
  @media (min-width: 960px) {
    .pb-md-4, .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pt-md-4, .py-md-4 {
      padding-top: 1.5rem !important;
    }
  }

  @media (min-width: 1264px) {
    .pb-lg-5, .py-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pt-lg-5, .py-lg-5 {
      padding-top: 3rem !important;
    }
  }
}
</style>